<template>
  <v-dialog
    v-model="dialog"
    max-width="40%"
    height="70vh"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title>Assignees</v-card-title>
      <v-divider />
      <v-card-text
        style="max-height: 70vh"
        class="overflow-y-auto overflow-x-hidden pt-2"
      >
        <v-text-field
          outlined
          label="Search for employee by(Name, employee number)"
          color="primary"
          dense
          prepend-inner-icon="mdi-magnify"
          ref="search"
          clearable
          v-model="search"
          @click:clear="clearSearch"
        />
        <v-list dense>
          <v-list-item-group v-model="selectedItem" color="primary">
            <template
              v-for="item in search.length > 0 ? filteredItems : assignees"
            >
              <v-list-item :key="item.number" @click="save(item)">
                <v-list-item-avatar>
                  <v-avatar
                    v-if="item.assignedCode !== null"
                    class="mr-2 white--text"
                    :size="32"
                    :color="userColor(item.search_name || item.searchName)"
                  >
                    {{ getAssigneeAvatar(item.search_name || item.searchName ) }}
                  </v-avatar>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{
                     ( item.first_name +
                      " " +
                      item.middle_name +
                      " " +
                      item.last_name) || item.name
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.company_email ||  item.eMail }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import programmeMixin from "../programmeMixin";
import helper from "../../utils/helpers/helper";
export default {
  name: "",
  mixins: [programmeMixin],
  props: { employeeDialog: Boolean },
  data() {
    return {
      search: "",
      selectedItem: null,
    };
  },
  computed: {
    employeeAssignees() {
      return this.$store.getters["Programme/programmeGetters"]("assignees");
    },
    subawardeeAssignees() {
      return this.$store.getters["Programme/programmeGetters"]("subAwardeeAssignees");
    },
    assignees() {
      return [...this.employeeAssignees, this.subawardeeAssignees];
    },
    filteredItems() {
      const data = this.assignees.filter((item) => {
        if (!this.search) return this.assignees;
        return (
          item.number.toLowerCase().includes(this.search.toLowerCase()) ||
          item.search_name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.last_name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.first_name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.middle_name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.no.toLowerCase().includes(this.search.toLowerCase()) ||
          item.name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.searchName.toLowerCase().includes(this.search.toLowerCase())
        );
      });
      return data;
    },
    dialog: {
      get() {
        return this.employeeDialog;
      },
      set(val) {
        this.$emit(":update:employeeDialog", val);
      },
    },
  },
  methods: {
    save(item) {
      this.dialog = false;
      this.clearSearch();
      const { code, documentNo, documentType, beginObjectiveCodes } =
        this.selectedActivity;
      this.$store.dispatch("Programme/editActivity", {
        assignedCode: item.number || item.no,
        code,
        documentNo,
        documentType,
        beginObjectiveCodes,
      });
    },
    clearSearch() {
      this.search = "";
    },
    userColor(user) {
      if (user) {
        return helper.stringToColour(user);
      }
    },
    getAssigneeAvatar(words) {
      return words
        .split(" ")
        .map((word) => word[0])
        .slice(0, 2)
        .join("");
    },
  },
};
</script>
