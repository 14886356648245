<template>
  <div>
    <v-card :width="isCard ? '50%' : '100%'" flat light>
      <v-img
        v-if="company.logo"
        alt="Logo"
        :height="height"
        :width="width"
        :src="`data:image/png;base64,${company.logo}`"
        @click="$router.push({ name: 'Main' })"
      />
      <v-img
        v-else
        :height="height"
        :width="width"
        src="../../assets/logo-tjna.png"
        contain
      />
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    height: {
      type: [Number, String],
      default: () => 50,
    },
    width: {
      type: [Number, String],
      default: () => 50,
    },
    isCard: {
      type: Boolean,
      default: () => true,
    },
    rounded: {},
  },
  computed: {
    company() {
      return this.$store.getters["Landing/company"];
    },
  },
};
</script>

<style scoped></style>
